





















































































































import { defineComponent, computed, ref, watch } from '@vue/composition-api';
import type { ColumnOption } from './CampaignReport.vue';
import columsConfig from './columsConfig';
import cloneUtils from '@common/utils/clone.utils';

interface Props {
    columns: Array<ColumnOption>;
    showSort: boolean;
    defaultColumns: Array<any>;
    target: string;
    change: () => void;
}
const applyDrag = (arr: Array<any>, dragResult: { removedIndex: number; addedIndex: number; payload: any }) => {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;

    const result = [...arr];
    let itemToAdd = payload;

    if (removedIndex !== null) itemToAdd = result.splice(removedIndex, 1)[0];

    if (addedIndex !== null) result.splice(addedIndex, 0, itemToAdd);

    return result;
};

export default defineComponent({
    name: 'SortColumn',
    props: ['columns', 'change', 'showSort', 'target', 'defaultColumns', 'canSearch', 'needReset'],
    model: {
        prop: 'columns',
        event: 'change'
    },
    setup(props: Props, { emit }) {
        const innerColumns = ref<Props['columns']>([]);
        const sortableColumns = computed(() =>
            props.target === 'userlist' ? innerColumns.value : innerColumns.value.slice(2)
        );
        const columsConfigClone: Array<ColumnOption> = cloneUtils.deep(props.defaultColumns || columsConfig);
        watch(
            () => props.showSort,
            () => {
                if (props.showSort)
                    innerColumns.value = cloneUtils
                        .deep(props.columns)
                        .sort((column: any) => (column.key === 'name' ? -1 : 0))
                        .sort((column: any) => (column.key === 'thumbnail' ? -1 : 0));
            }
        );

        const handleSelectedChange = (key: ColumnOption['key']) => {
            const target = innerColumns.value.findIndex(column => column.key === key);
            if (target === -1)
                innerColumns.value = [
                    ...innerColumns.value,
                    cloneUtils.deep(columsConfigClone.find(column => column.key === key))
                ];
            else {
                const newVal = innerColumns.value;
                newVal.splice(target, 1);
                innerColumns.value = newVal;
            }
        };

        const handleClickSave = () => {
            const change = cloneUtils.deep(innerColumns.value);
            emit('change', change);
            emit('close');
        };
        const handleResetSave = () => {
            console.log('handle reset save');
            emit('reset');
            emit('close');
        };
        const handleDrop = (dropResult: { removedIndex: number; addedIndex: number; payload: any }) => {
            if (props.target === 'userlist') {
                const draggableList = innerColumns.value;
                innerColumns.value = [...applyDrag(draggableList, dropResult)];
            } else {
                const draggableList = innerColumns.value.slice(2);
                innerColumns.value = [
                    innerColumns.value[0],
                    innerColumns.value[1],
                    ...applyDrag(draggableList, dropResult)
                ];
            }
        };

        const defaultRenderColumns = computed(() => {
            return columsConfigClone
                ?.map(column => ({
                    ...column,
                    selected: Boolean(innerColumns.value.find(currentColumn => currentColumn.key === column.key))
                }))
                .filter(
                    (item: any) =>
                        item.key !== 'thumbnail' &&
                        (searchKey.value !== ''
                            ? item.name.toLowerCase().includes(searchKey.value.toLowerCase())
                            : true)
                );
        });
        const searchKey = ref<any>('');
        return {
            handleDrop,
            handleResetSave,
            handleSelectedChange,
            handleClickSave,
            defaultRenderColumns,
            innerColumns,
            sortableColumns,
            searchKey
        };
    }
});
